import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

type BadgeFlagProps = {
  locale: string;
};

const BadgeFlag = ({ locale }: BadgeFlagProps) => {
  switch (locale) {
    case 'en-US':
      return (
        <StaticImage
          src={`../../../static/assets/img/lang/en-US.png`}
          alt="American English"
          placeholder="blurred"
        />
      );
    case 'pt-BR':
      return (
        <StaticImage
          src={`../../../static/assets/img/lang/pt-BR.png`}
          alt="Brazilian Portuguese"
          placeholder="blurred"
        />
      );
    case 'it-IT':
      return (
        <StaticImage
          src={`../../../static/assets/img/lang/it-IT.png`}
          alt="Italian"
          placeholder="blurred"
        />
      );
  }
};

export default BadgeFlag;
