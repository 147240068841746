import React from 'react';
import { CategoryTagColor } from '../../models';
import getThemeColor from '../../utils/getThemeColor';
import CategorySticker from '../CategorySticker';
import CategoryTag from '../CategoryTag';

import * as S from './style';
import BadgeFlag from '../BadgeFlag';

type PostItemProps = {
  slug: string;
  image?: string;
  category: string;
  locale: string;
  date: string;
  timeToRead?: string;
  title: string;
  description: string;
};

const PostItem = ({
  slug,
  category,
  locale,
  date,
  timeToRead,
  title,
  description
}: PostItemProps) => (
  <S.PostItemLink
    cover
    direction="left"
    bg={getThemeColor()}
    duration={0.6}
    to={slug}
  >
    <S.PostItemWrap>
      <S.CategoryTagWrap background={CategoryTagColor[category]}>
        <CategoryTag category={category} locale={locale} />
      </S.CategoryTagWrap>
      <S.PostItemTag title={category}>
        <CategorySticker category={category} />
      </S.PostItemTag>
      <S.PostItemInfo>
        <S.LocaleBadgeWrap title={locale}>
          <BadgeFlag locale={locale} />
        </S.LocaleBadgeWrap>
        <S.PostItemDate>
          {date} • {timeToRead}
        </S.PostItemDate>
        <S.PostItemTitle>{title}</S.PostItemTitle>
        <S.PostItemDescription>{description}</S.PostItemDescription>
      </S.PostItemInfo>
    </S.PostItemWrap>
  </S.PostItemLink>
);
export default PostItem;
