import React, { ReactElement } from 'react';

export type WithChildren<T = unknown> = T & { children?: React.ReactNode };

export const CategoryTagColor: { [key: string]: string } = {
  TECH: 'orange',
  HISTORY: 'grey',
  LEISURE: 'green',
  REFLECTIONS: 'blue',
  TRAVEL: 'brown'
};

export type Tech = {
  name: string;
  icon: ReactElement;
};
