import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import * as S from './style';
import getThemeColor from '../../utils/getThemeColor';

type PaginationProps = {
  isFirst: boolean;
  isLast: boolean;
  currentPage: number;
  numPages: number;
  prevPage?: string;
  nextPage?: string;
};

const Pagination = ({
  isFirst,
  isLast,
  currentPage,
  numPages,
  prevPage,
  nextPage
}: PaginationProps) => (
  <S.PaginationWrapper>
    {!isFirst && (
      <AniLink
        cover
        direction="right"
        bg={getThemeColor()}
        duration={0.6}
        to={prevPage}
      >
        ← previous page
      </AniLink>
    )}
    <p>
      {currentPage} de {numPages}
    </p>
    {!isLast && (
      <AniLink
        cover
        direction="left"
        bg={getThemeColor()}
        duration={0.6}
        to={nextPage}
      >
        next page →
      </AniLink>
    )}
  </S.PaginationWrapper>
);

export default Pagination;
