import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import Pagination from '../components/Pagination';

import * as S from '../components/ListWrap/style';

type BlogListProps = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          fields: { slug: string };
          frontmatter: {
            title: string;
            date: string;
            description: string;
            category: string;
            locale: string;
          };
          timeToRead: string;
        };
      }[];
    };
  };
  pageContext: { currentPage: number; numPages: number };
};

const BlogList = (props: BlogListProps) => {
  const postList = props.data.allMarkdownRemark.edges;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : `/page/${currentPage - 1}`;
  const nextPage = `/page/${currentPage + 1}`;

  return (
    <Layout>
      <SEO title="Blog" />
      <S.ListWrap>
        {postList.map(
          ({
            node: {
              frontmatter: { category, date, description, title, locale },
              timeToRead,
              fields: { slug }
            }
          }) => (
            <PostItem
              key={slug}
              slug={slug}
              category={category}
              locale={locale}
              date={new Date(date).toLocaleDateString(locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
              timeToRead={`${timeToRead}${
                ['-minute read', ' min. de leitura', ' min. di lettura'][
                  ['en-US', 'pt-BR', 'it-IT'].indexOf(locale)
                ]
              }`}
              title={title}
              description={description}
            />
          )
        )}
      </S.ListWrap>
      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Layout>
  );
};

export const query = graphql`
  query PostList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            category
            locale
          }
          timeToRead
        }
      }
    }
  }
`;

export default BlogList;
