import React from 'react';

type CategoryTagProps = {
  category: string;
  locale: string;
};

const LocaleIndex: { [key: string]: number } = {
  en: 0,
  pt: 1,
  it: 2
};

const CategoriesDic: { [key: string]: string[] } = {
  TECH: ['TECH', 'TECNOLOGIA', 'TECNOLOGIE'],
  HISTORY: ['HISTORY', 'HISTÓRIA', 'STORIA'],
  LEISURE: ['LEISURE', 'ENTRETENIMENTO', 'SVAGO'],
  REFLECTIONS: ['REFLECTIONS', 'REFLEXÕES', 'RIFLESSIONI'],
  TRAVEL: ['TRAVEL', 'VIAGENS', 'VIAGGI']
};

const CategoryTag = ({ locale, category }: CategoryTagProps) => {
  return <p>{CategoriesDic[category][LocaleIndex[locale.split('-')[0]]]}</p>;
};

export default CategoryTag;
